import Image from 'next/image';
import Link from 'next/link';
import DarkMode from '../mode/DarkMode';
import Logo from './../../public/tevkilapp-color-logo.png';
import WhiteLogo from './../../public/tevkilapp-white-logo.png';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {UserNotLoggedIn} from '@/container/header/dropdown/UserNotLoggedIn';
import {UserLoggedInDropdown} from '@/container/header/dropdown/UserLoggedInDropdown';
import {UserAuthDropdown} from '@/container/header/dropdown/UserAuthDropdown';
import {useRouter} from 'next/router';
import {BsCardChecklist, BsDoorOpen, BsLifePreserver, BsPersonAdd, BsPersonGear, BsPlusLg, BsReceipt, BsStar} from 'react-icons/bs';
import {HiQueueList} from 'react-icons/hi2';
import {logout} from '../../redux/slices/userSlice';
import Cookies from 'js-cookie';

export default function Header() {
	const router = useRouter();

	const user = useSelector(state => state.user);

	const [toggle, setToggle] = useState(false);
	const [showNav, setShowNav] = useState(false);
	const [isLogged, setLogged] = useState(false);
	const [mobileUserDropdown, setMobileUserDropdown] = useState(false);

	// window resize
	useEffect(() => {
		if (user.user) {
			setLogged(true);
		}

	}, [user.user]);

	useEffect(() => {
		if (router.pathname.startsWith('/uyelik')) {
			setShowNav(false);
		}
		else {
			setShowNav(true);
		}
	}, [router]);

	useEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth >= 1024) {
				setToggle(false);
			}
		});
	}, []);

	return (
		(showNav ? (
			<>
				{/* main dropdown menu sart*/}
				<header
					className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors dark:bg-none">
					<div className="flex items-center px-6 py-6 xl:px-24 ">
						<Link className="shrink-0" href="/">
							<div className="dark:hidden">
								<Image
									src={Logo}
									style={{height: 'auto', width: '170px'}}
									alt="TevkilApp | Avukatlar arası görev paylaşım platformu"
								/>
							</div>
							<div className="hidden dark:block">
								<Image
									src={WhiteLogo}

									style={{height: 'auto', width: '170px'}}
									alt="TevkilApp | Avukatlar arası görev paylaşım platformu"
								/>
							</div>
						</Link>
						{/* End  logo */}


						<div
							className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
							<nav className="navbar w-full">
								<ul className="flex flex-col lg:flex-row">

									{isLogged ? (<li className="group">
										<Link href="/gorevler/liste" className="user-login-menu-link">
											<button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
												<span>Aktif Görevler</span>
											</button>
										</Link>
									</li>) : (<span className="hidden-style"></span>)}

									{/* home */}
									<li className="group">
										<Link href="/tevkilapp-nasil-calisir">
											<button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
												<span>Nasıl Çalışır?</span>
											</button>
										</Link>
									</li>

									<li className="js-nav-dropdown nav-item dropdown group relative">
										<Link href="/hakkimizda">
											<button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
												<span>Hakkımızda</span>
												<i className="lg:hidden">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width={24}
														height={24}
														className="h-4 w-4 dark:fill-white"
													>
														<path fill="none" d="M0 0h24v24H0z"/>
														<path
															d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/>
													</svg>
												</i>
											</button>
										</Link>
									</li>

									{/* resource */}
									<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
										<Link href="/iletisim">
											<button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
												<span>İletişim</span>
												<i className="lg:hidden">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
														width={24}
														height={24}
														className="h-4 w-4 dark:fill-white"
													>
														<path fill="none" d="M0 0h24v24H0z"/>
														<path
															d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/>
													</svg>
												</i>
											</button>
										</Link>
									</li>

									{/* create */}

									<li className="group">
										<Link href="/sss">
											<button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
												<span> S.S.S.</span>
											</button>
										</Link>
									</li>
								</ul>
							</nav>
							{/* End menu for dropdown */}

							<div className="ml-8 hidden items-center lg:flex xl:ml-12">
								<div className="js-nav-dropdown group-dropdown relative">
									<button
										className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width={24}
											height={24}
											className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
										>
											<path fill="none" d="M0 0h24v24H0z"/>
											<path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"/>
										</svg>
									</button>
									<div
										className="dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[16rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">

										{isLogged ? (<UserLoggedInDropdown mobileUserDropdown={mobileUserDropdown} setMobileUserDropdown={setMobileUserDropdown} user={user.user}/>) : (<UserNotLoggedIn/>)}

									</div>
								</div>
								<DarkMode/>
							</div>
							{/* End header right content (metamask and other) for dropdown */}
						</div>
						{/* header menu conent end for dropdown */}

						{/*mobile taraflı user dropdown kısmı*/}
						{/*
						<div className="ml-auto flex relative lg:hidden">
							<div className="">
								<button
									onClick={() => setMobileUserDropdown(!mobileUserDropdown)}
									className={`absolute !right-24 border-jacarta-100 ${mobileUserDropdown ? 'bg-accent dark:bg-accent border-transparent' : 'dark:bg-white/[.15]  bg-white '} group ml-2 flex h-10 w-10 items-center justify-center rounded-full border  transition-colors  dark:border-transparent `}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										className={` h-4 w-4 transition-colors  ${mobileUserDropdown ? 'fill-white  ' : 'fill-jacarta-700 dark:fill-white'}`}
									>
										<path fill="none" d="M0 0h24v24H0z"/>
										<path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"/>
									</svg>
								</button>
								<div
									style={{display: mobileUserDropdown ? 'block' : 'none'}}
									className="dark:bg-jacarta-800 mt-5 pt-4 pb-4 !right-14 absolute !top-[85%] !left-auto z-10 min-w-[16rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl">
									{isLogged ? (<UserLoggedInDropdown mobileUserDropdown={mobileUserDropdown} setMobileUserDropdown={setMobileUserDropdown} user={user.user}/>) : (<UserNotLoggedIn/>)}

								</div>
							</div>


							<button
								className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
								aria-label="open mobile menu"
								onClick={() => setToggle(true)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width={24}
									height={24}
									className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
								>
									<path fill="none" d="M0 0h24v24H0z"/>
									<path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z"/>
								</svg>
							</button>

						</div>
						*/}
						{/* End header right content  for mobile */}
					</div>
					{/* End flex item */}
				</header>
				{/* main dropdown menu end */}

				{/* start mobile menu and it's other materials  */}
				<div className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${toggle ? 'nav-menu--is-open' : 'hidden'}`}>
					<div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
						<div className="dark:hidden">
							<Image
								src={Logo}
								height={28}
								width={130}
								alt="TevkilApp - İşlerinizi Kolaylaştırın, Sonuçları Hızlandırın, Hukuki Yükümlülükleri TevkilApp İle Sorunsuz Yönetin!"
								className="max-h-7 h-auto "
							/>
						</div>

						<div className="hidden dark:block">
							<Image
								src={WhiteLogo}
								height={28}
								width={130}
								alt="TevkilApp - İşlerinizi Kolaylaştırın, Sonuçları Hızlandırın, Hukuki Yükümlülükleri TevkilApp İle Sorunsuz Yönetin!"
							/>
						</div>

						<button
							className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
							onClick={() => setToggle(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width={24}
								height={24}
								className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
							>
								<path fill="none" d="M0 0h24v24H0z"/>
								<path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
							</svg>
						</button>
					</div>

					<nav className="navbar w-full">
						<ul className="flex flex-col lg:flex-row" style={{marginTop: '5rem'}}>
							{user.user && (
								<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
									<Link href="/gorevler/liste">
										<button onClick={() => setToggle(false)} className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
											<span>Aktif Görevler</span>
										</button>
									</Link>
								</li>
							)}
							<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
								<Link href="/">
									<button
										onClick={() => setToggle(false)}
										className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
									>
										<span>Anasayfa</span>
									</button>
								</Link>
							</li>
							<li className="js-nav-dropdown nav-item dropdown group relative">
								<Link href="/hakkimizda">
									<button
										onClick={() => setToggle(false)}
										className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
									>
										<span>Hakkımızda</span>
									</button>
								</Link>
							</li>
							<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
								<Link href="/iletisim">
									<button
										onClick={() => setToggle(false)}
										className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
									>
										<span>İletişim</span>
									</button>
								</Link>
							</li>
							<li className="group" onClick={() => setToggle(false)}>
								<Link href="/tevkilapp-nasil-calisir">
									<button
										onClick={() => setToggle(false)}
										className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
										<span>Nasıl Çalışır?</span>
									</button>
								</Link>
							</li>

							<li className="group" onClick={() => setToggle(false)}>
								<Link href="/sss">
									<button
										className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
										<span>SSS</span>
									</button>
								</Link>
							</li>
						</ul>
					</nav>
					{/* End navbar mobile menu  */}

					{user.user && (
						<>
							<div className="w-full lg:hidden">
								<hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0"/>
								<div className="flex flex-col">
									<div className="flex flex-row bg-gray-100 p-3 rounded-lg align-middle gap-3 mt-0 items-center dark:bg-jacarta-900">
										<Image src={`https://cdn.tevkilapp.com/${user.user.avatar}`}
												 style={{height: 'auto', width: 'auto'}}
												 height={50}
												 alt="avatar"
												 width={50}
												 quality={90}
												 className="rounded-lg max-h-[60px]"/>
										<div>
											<div className="font-display font-semibold text-sm text-three-dot-mobile sm:text-three-dot"
												  style={{height: '18px'}}>
												{user.user.full_name.length > 18 ? user.user.full_name.substring(0, 15) + '...' : user.user.full_name}
											</div>

											<span className="text-sm">Avukat</span>
										</div>
									</div>
									<ul className="flex flex-col lg:flex-row">
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/premium"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5">
												<BsStar></BsStar>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Premium Üyelik</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/gorevler/olustur"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsPlusLg></BsPlusLg>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Görev Oluştur</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/gorevler/gorevlerim"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsReceipt></BsReceipt>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Oluşturduğum Görevler</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/gorevler/katildiklarim"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsCardChecklist></BsCardChecklist>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Başvurduğum Görevler</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/sira"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<HiQueueList></HiQueueList>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Görev Sıralarım</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/ayarlar/avatar-degistir"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsPersonGear></BsPersonGear>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Ayarlarım</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/destek/liste"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsLifePreserver></BsLifePreserver>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Destek Sistemi</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/referans"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsPersonAdd></BsPersonAdd>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Referans Sistemi</span>
											</Link>
										</li>
										<li className="js-nav-dropdown group relative" onClick={() => setToggle(false)}>
											<Link
												href="/uyelik/cikis"
												className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center gap-4 py-3.5 text-base dark:text-white lg:px-5"
											>
												<BsDoorOpen></BsDoorOpen>
												<span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">Çıkış Yap</span>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</>
					)}

					<div className="w-full lg:hidden">
						<hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0"/>
						<div className="flex items-center justify-center space-x-5">
							<div className="group">
								<Link href="https://facebook.com/tevkilapp" target="_blank">
									<svg
										aria-hidden="true"
										focusable="false"
										data-prefix="fab"
										data-icon="facebook"
										className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
									>
										<path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
									</svg>
								</Link>
							</div>
							<div className="group">
								<Link href="https://twitter.com/tevkilappcom" target="_blank">
									<svg
										aria-hidden="true"
										focusable="false"
										data-prefix="fab"
										data-icon="twitter"
										className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 512 512"
									>
										<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
									</svg>
								</Link>
							</div>
							<div className="group">
								<Link href="https://instagram.com/tevkilappcom" target="_blank">
									<svg
										aria-hidden="true"
										focusable="false"
										data-prefix="fab"
										data-icon="instagram"
										className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 448 512"
									>
										<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
									</svg>
								</Link>
							</div>
						</div>
					</div>

					<div className="w-full lg:hidden pb-20">
						<hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0"/>
						<div className="flex items-center justify-center space-x-5">
							<DarkMode/>
						</div>
					</div>
					{/* mt-10 w-full lg:hidden */}
				</div>
				{/* End mobile menu and it's other materials */}

				<div className="fixed lg:hidden bottom-0 left-0 w-full bg-white dark:bg-jacarta-900 z-20 border-t dark:border-t-jacarta-600">
					<div className="flex flex-row py-4">

						{isLogged ? (
							<>
								<div className="basis-1/4">
									<Link href="/gorevler/liste">
										<div className="flex flex-col items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
												<path d="M11 4H21V6H11V4ZM11 8H17V10H11V8ZM11 14H21V16H11V14ZM11 18H17V20H11V18ZM3 4H9V10H3V4ZM5 6V8H7V6H5ZM3 14H9V20H3V14ZM5 16V18H7V16H5Z"></path>
											</svg>
											<span className="text-xs text-black dark:text-white/90">Aktif Görevler</span>
										</div>
									</Link>
								</div>
								<div className="basis-1/4">
									<Link href="/gorevler/olustur">
										<div className="flex flex-col items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
												<path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
											</svg>
											<span className="text-xs text-black dark:text-white/90">Görev Oluştur</span>
										</div>
									</Link>
								</div>
								<div className="basis-1/4">
									<Link href="/sira">
										<div className="flex flex-col items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
												<path d="M5.75024 3.5H4.71733L3.25 3.89317V5.44582L4.25002 5.17782L4.25018 8.5H3V10H7V8.5H5.75024V3.5ZM10 4H21V6H10V4ZM10 11H21V13H10V11ZM10 18H21V20H10V18ZM2.875 15.625C2.875 14.4514 3.82639 13.5 5 13.5C6.17361 13.5 7.125 14.4514 7.125 15.625C7.125 16.1106 6.96183 16.5587 6.68747 16.9167L6.68271 16.9229L5.31587 18.5H7V20H3.00012L2.99959 18.8786L5.4717 16.035C5.5673 15.9252 5.625 15.7821 5.625 15.625C5.625 15.2798 5.34518 15 5 15C4.67378 15 4.40573 15.2501 4.37747 15.5688L4.3651 15.875H2.875V15.625Z"></path>
											</svg>
											<span className="text-xs text-black dark:text-white/90">Görev Sıralarım</span>
										</div>
									</Link>
								</div>
								<div className="basis-1/4">
									<div className="flex flex-col items-center" onClick={() => setToggle(true)}>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
											<path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z"></path>
										</svg>
										<span className="text-xs text-black dark:text-white/90">Menü</span>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="basis-1/4">
									<Link href="/">
										<div className="flex flex-col items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
												<path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path>
											</svg>
											<span className="text-xs text-black dark:text-white/90">Anasayfa</span>
										</div>
									</Link>
								</div>
								<div className="basis-1/4">
									<Link href="/uyelik/giris">
										<div className="flex flex-col items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
												<path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18.5858 17L16.7574 15.1716L18.1716 13.7574L22.4142 18L18.1716 22.2426L16.7574 20.8284L18.5858 19H15V17H18.5858Z"></path>
											</svg>
											<span className="text-xs text-black dark:text-white/90">Giriş Yap</span>
										</div>
									</Link>
								</div>
								<div className="basis-1/4">
									<Link href="/uyelik/kayit">
										<div className="flex flex-col items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
												<path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V14H20V17H23V19H20V22H18V19H15V17H18Z"></path>
											</svg>
											<span className="text-xs text-black dark:text-white/90">Kayıt Ol</span>
										</div>
									</Link>
								</div>
								<div className="basis-1/4">
									<div className="flex flex-col items-center" onClick={() => setToggle(true)}>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
											<path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z"></path>
										</svg>
										<span className="text-xs text-black dark:text-white/90">Menü</span>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</>
		) : (isLogged && (
			<>
				{/* main dropdown menu sart*/}
				<header
					className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors dark:bg-none">
					<div className="flex items-center px-6 py-6 xl:px-24 ">
						<Link className="shrink-0" href="/">
							<div className="dark:hidden">
								<Image
									src={Logo}
									style={{height: 'auto', width: '170px'}}
									alt="TevkilApp | Avukatlar arası görev paylaşım platformu"
								/>
							</div>
							<div className="hidden dark:block">
								<Image
									src={WhiteLogo}

									style={{height: 'auto', width: '170px'}}
									alt="TevkilApp | Avukatlar arası görev paylaşım platformu"
								/>
							</div>
						</Link>
						{/* End  logo */}


						<div
							className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">

							<div className="ml-8 hidden items-center lg:flex xl:ml-12">
								<div className="js-nav-dropdown group-dropdown relative">
									<button
										className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width={24}
											height={24}
											className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
										>
											<path fill="none" d="M0 0h24v24H0z"/>
											<path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"/>
										</svg>
									</button>
									<div
										className="dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[16rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">

										{isLogged ? (<UserAuthDropdown mobileUserDropdown={mobileUserDropdown} setMobileUserDropdown={setMobileUserDropdown} user={user.user}/>) : (<UserNotLoggedIn/>)}

									</div>
								</div>
								<DarkMode/>
							</div>
							{/* End header right content (metamask and other) for dropdown */}
						</div>
						{/* header menu conent end for dropdown */}

						{/*mobile taraflı user dropdown kısmı*/}
						<div className="ml-auto flex relative lg:hidden">
							<div className="">
								<button
									onClick={() => setMobileUserDropdown(!mobileUserDropdown)}
									className={`!right-24 border-jacarta-100 ${mobileUserDropdown ? 'bg-accent dark:bg-accent border-transparent' : 'dark:bg-white/[.15]  bg-white '} group ml-2 flex h-10 w-10 items-center justify-center rounded-full border  transition-colors  dark:border-transparent `}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width={24}
										height={24}
										className={` h-4 w-4 transition-colors  ${mobileUserDropdown ? 'fill-white  ' : 'fill-jacarta-700 dark:fill-white'}`}
									>
										<path fill="none" d="M0 0h24v24H0z"/>
										<path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"/>
									</svg>
								</button>
								<div
									style={{display: mobileUserDropdown ? 'block' : 'none'}}
									className="dark:bg-jacarta-800 mt-5 pt-4 pb-4 !right-14 absolute !top-[85%] !left-auto z-10 min-w-[16rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl">
									{isLogged ? (<UserAuthDropdown mobileUserDropdown={mobileUserDropdown} setMobileUserDropdown={setMobileUserDropdown} user={user.user}/>) : (<UserNotLoggedIn/>)}

								</div>
							</div>
							<DarkMode/>
						</div>
						{/* End header right content  for mobile */}
					</div>
					{/* End flex item */}
				</header>
				{/* main dropdown menu end */}

				{/* start mobile menu and it's other materials  */}
				<div className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${toggle ? 'nav-menu--is-open' : 'hidden'}`}>
					<div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
						<div className="dark:hidden">
							<Image
								src={Logo}
								height={28}
								width={130}
								alt="TevkilApp - İşlerinizi Kolaylaştırın, Sonuçları Hızlandırın, Hukuki Yükümlülükleri TevkilApp İle Sorunsuz Yönetin!"
								className="max-h-7 h-auto "
							/>
						</div>

						<div className="hidden dark:block">
							<Image
								src={WhiteLogo}
								height={28}
								width={130}
								alt="TevkilApp - İşlerinizi Kolaylaştırın, Sonuçları Hızlandırın, Hukuki Yükümlülükleri TevkilApp İle Sorunsuz Yönetin!"
							/>
						</div>

						<button
							className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
							onClick={() => setToggle(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width={24}
								height={24}
								className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
							>
								<path fill="none" d="M0 0h24v24H0z"/>
								<path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
							</svg>
						</button>
					</div>

					{/* End navbar mobile menu  */}

					<div className="w-full lg:hidden">

						<hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0"/>
						<div className="flex items-center justify-center space-x-5">
							<a className="group">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fab"
									data-icon="facebook"
									className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
								</svg>
							</a>
							<a className="group">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fab"
									data-icon="twitter"
									className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
								</svg>
							</a>
							<a className="group">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fab"
									data-icon="discord"
									className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 640 512"
								>
									<path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
								</svg>
							</a>
							<a className="group">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fab"
									data-icon="instagram"
									className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
								</svg>
							</a>
							<a className="group">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fab"
									data-icon="tiktok"
									className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
								</svg>
							</a>
						</div>
					</div>
					{/* mt-10 w-full lg:hidden */}
				</div>
				{/* End mobile menu and it's other materials */}

				{/*
				<div className="fixed bottom-0 left-0 w-full bg-red-500">
					asd
				</div>
				*/}
			</>
		))));
}
